export default () => {
  /**
   * Workaround for scroll not resetting after layout
   * https://github.com/nuxt/nuxt/pull/23769
   */
  onMounted(() => {
    const { y } = useWindowScroll()

    y.value = 0
  })
}
